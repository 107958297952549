window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.front_royal_upload = window.locales.am.front_royal_upload || {};window.locales.am.front_royal_upload.front_royal_upload = window.locales.am.front_royal_upload.front_royal_upload || {};window.locales.am.front_royal_upload.front_royal_upload = {...window.locales.am.front_royal_upload.front_royal_upload, ...{
    "that_file_is_not_valid_allowed_types_are": "ይቅርታ፣ ያንን የፋይል አይነት አንቀበልም። የተፈቀዱ ዓይነቶች {{allowed_types}} ናቸው።",           
    "invalid_file": "ልክ ያልሆነ የፋይል አይነት",           
    "that_file_is_not_valid": "ይቅርታ፣ ያንን የፋይል አይነት አንቀበልም።",           
    "max_size_exceeded": "ይቅርታ፣ የሰቀሉት ፋይል ከከፍተኛው የፋይል መጠን ይበልጣል፡- {{size}}።",           
    "unknown_file_issue": "ይቅርታ፣ በመረጡት ፋይል ላይ ችግር አለ።"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.front_royal_upload = window.locales.ar.front_royal_upload || {};window.locales.ar.front_royal_upload.front_royal_upload = window.locales.ar.front_royal_upload.front_royal_upload || {};window.locales.ar.front_royal_upload.front_royal_upload = {...window.locales.ar.front_royal_upload.front_royal_upload, ...{
    "that_file_is_not_valid_allowed_types_are": "آسف، نحن لا نقبل هذا النوع من الملفات.   أنواع المسموح بها هي {{allowed_types}} .",
    "invalid_file": "نوع الملف غير صالح",
    "that_file_is_not_valid": "آسف، نحن لا نقبل هذا النوع من الملفات.",
    "max_size_exceeded": "عذرا، يتجاوز التحميل الحد الأقصى لحجم الملف: {{size}} .",
    "unknown_file_issue": "عذرًا ، هناك مشكلة في الملف الذي حددته."
}
};window.locales.en = window.locales.en || {};window.locales.en.front_royal_upload = window.locales.en.front_royal_upload || {};window.locales.en.front_royal_upload.front_royal_upload = window.locales.en.front_royal_upload.front_royal_upload || {};window.locales.en.front_royal_upload.front_royal_upload = {...window.locales.en.front_royal_upload.front_royal_upload, ...{
    "that_file_is_not_valid_allowed_types_are": "Sorry, we don't accept that file type. Allowed types are {{allowed_types}}.",
    "invalid_file": "Invalid File Type",
    "that_file_is_not_valid": "Sorry, we don't accept that file type.",
    "max_size_exceeded": "Sorry, your upload exceeds the maximum file size: {{size}}.",
    "unknown_file_issue": "Sorry, there is a problem with the file you have selected."
}
};window.locales.es = window.locales.es || {};window.locales.es.front_royal_upload = window.locales.es.front_royal_upload || {};window.locales.es.front_royal_upload.front_royal_upload = window.locales.es.front_royal_upload.front_royal_upload || {};window.locales.es.front_royal_upload.front_royal_upload = {...window.locales.es.front_royal_upload.front_royal_upload, ...{
    "that_file_is_not_valid_allowed_types_are": "Lo sentimos, no aceptamos ese tipo de archivo.  Tipos permitidos son {{allowed_types}} .",
    "invalid_file": "Tipo de archivo invalido",
    "that_file_is_not_valid": "Lo sentimos, no aceptamos ese tipo de archivo.",
    "max_size_exceeded": "Lo sentimos, su subida supera el tamaño máximo de archivo: {{size}} .",
    "unknown_file_issue": "Lo sentimos, hay un problema con el archivo que ha seleccionado."
}
};window.locales.it = window.locales.it || {};window.locales.it.front_royal_upload = window.locales.it.front_royal_upload || {};window.locales.it.front_royal_upload.front_royal_upload = window.locales.it.front_royal_upload.front_royal_upload || {};window.locales.it.front_royal_upload.front_royal_upload = {...window.locales.it.front_royal_upload.front_royal_upload, ...{
    "that_file_is_not_valid_allowed_types_are": "Siamo spiacenti, non accettiamo questo tipo di file. I tipi di file ammessi sono {{allowed_types}}.",           
    "invalid_file": "Tipo di file non valido",           
    "that_file_is_not_valid": "Siamo spiacenti, non accettiamo questo tipo di file.",           
    "max_size_exceeded": "Spiacenti, il tuo caricamento supera la dimensione massima del file: {{size}} .",
    "unknown_file_issue": "Siamo spiacenti, c'è un problema con il file che hai selezionato."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.front_royal_upload = window.locales.zh.front_royal_upload || {};window.locales.zh.front_royal_upload.front_royal_upload = window.locales.zh.front_royal_upload.front_royal_upload || {};window.locales.zh.front_royal_upload.front_royal_upload = {...window.locales.zh.front_royal_upload.front_royal_upload, ...{
    "that_file_is_not_valid_allowed_types_are": "很抱歉，该文件类型不可用。可用类型为{{allowed_types}}。",           
    "invalid_file": "无效文件类型",           
    "that_file_is_not_valid": "很抱歉，该文件类型不可用。",           
    "max_size_exceeded": "很抱歉，你上传的文件超过了最大文件大小：{{size}}。",           
    "unknown_file_issue": "很抱歉，你选择的文件有问题。"           
}
};