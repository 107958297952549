window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.linkedin = window.locales.am.linkedin || {};window.locales.am.linkedin.linkedin_profile_button = window.locales.am.linkedin.linkedin_profile_button || {};window.locales.am.linkedin.linkedin_profile_button = {...window.locales.am.linkedin.linkedin_profile_button, ...{
    "add_to_profile_img_src": "https://download.linkedin.com/desktop/add2profile/buttons/en_US.png",           
    "add_to_profile_alt": "ወደ ሊንከዲን ግለ-መገለጫ አክል"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.linkedin = window.locales.ar.linkedin || {};window.locales.ar.linkedin.linkedin_profile_button = window.locales.ar.linkedin.linkedin_profile_button || {};window.locales.ar.linkedin.linkedin_profile_button = {...window.locales.ar.linkedin.linkedin_profile_button, ...{
    "add_to_profile_img_src": "https://download.linkedin.com/desktop/add2profile/buttons/en_US.png",           
    "add_to_profile_alt": "أضف إلى الملف الشخصي على LinkedIn"           
}
};window.locales.en = window.locales.en || {};window.locales.en.linkedin = window.locales.en.linkedin || {};window.locales.en.linkedin.linkedin_profile_button = window.locales.en.linkedin.linkedin_profile_button || {};window.locales.en.linkedin.linkedin_profile_button = {...window.locales.en.linkedin.linkedin_profile_button, ...{
    "add_to_profile_img_src": "https://download.linkedin.com/desktop/add2profile/buttons/en_US.png",
    "add_to_profile_alt": "Add to LinkedIn Profile"
}
};window.locales.es = window.locales.es || {};window.locales.es.linkedin = window.locales.es.linkedin || {};window.locales.es.linkedin.linkedin_profile_button = window.locales.es.linkedin.linkedin_profile_button || {};window.locales.es.linkedin.linkedin_profile_button = {...window.locales.es.linkedin.linkedin_profile_button, ...{
    "add_to_profile_img_src": "https://download.linkedin.com/desktop/add2profile/buttons/en_US.png",           
    "add_to_profile_alt": "Añadir al perfil de LinkedIn "           
}
};window.locales.it = window.locales.it || {};window.locales.it.linkedin = window.locales.it.linkedin || {};window.locales.it.linkedin.linkedin_profile_button = window.locales.it.linkedin.linkedin_profile_button || {};window.locales.it.linkedin.linkedin_profile_button = {...window.locales.it.linkedin.linkedin_profile_button, ...{
    "add_to_profile_img_src": "https://download.linkedin.com/desktop/add2profile/buttons/en_US.png",           
    "add_to_profile_alt": "Aggiungi al Profilo LinkedIn"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.linkedin = window.locales.zh.linkedin || {};window.locales.zh.linkedin.linkedin_profile_button = window.locales.zh.linkedin.linkedin_profile_button || {};window.locales.zh.linkedin.linkedin_profile_button = {...window.locales.zh.linkedin.linkedin_profile_button, ...{
    "add_to_profile_img_src": "https://download.linkedin.com/desktop/add2profile/buttons/en_US.png",           
    "add_to_profile_alt": "添加到 LinkedIn 资料"           
}
};