import { type ReactElement, type ReactNode, memo } from 'react';
import { type auto } from 'angular';
import { Provider } from 'react-redux';
import { AngularProvider } from 'AngularContext';
import { useTargetBrandConfig } from 'FrontRoyalAngular/useTargetBrandConfig';
import clsx from 'clsx';
import { type configureStore } from '@reduxjs/toolkit';
import { NextUIProvider } from '@nextui-org/react';

export function TailwindBaseContainer({
    children,
    targetTailwindBaseStyles,
    className,
}: {
    children: ReactNode;
    targetTailwindBaseStyles: boolean;
    className?: string;
}) {
    const targetBrandConfig = useTargetBrandConfig();
    return (
        // The .tailwind-base-container class is used as a target for the custom Tailwind CSS preflight styles.
        // if targetTailwindBaseStyles is passed as false, we won't clear the global styling.
        // This lets us keep using the global styles on react code that was made before switching to tailwind
        // FIXME: Those places should be updated and 'tailwind-base-container' should be enabled by default when possible
        <div
            className={clsx(
                // The brandStyleClass controls the nextui theme defined in the tailwind config (if it is set to valar).
                // It also controls the variants like `quantic:` `valar:` etc. (see this code in tailwind.config.js: `addVariant(brand, `.${brand} &`)`)
                // We do something similar in setBodyBackground, but there we're targeting deprecated css.
                targetBrandConfig.brandStyleClass,
                {
                    'tailwind-base-container': targetTailwindBaseStyles,
                },
                className,
            )}
        >
            {children}
        </div>
    );
}

type Props = {
    children: ReactElement;
    angularInjector: auto.IInjectorService;
    classNames?: string;
    targetTailwindBaseStyles?: boolean;
    provideNextUi?: boolean;
    store: ReturnType<typeof configureStore>;
};
const ReactWrapperComponent: React.FC<Props> = ({
    store,
    children,
    angularInjector,
    classNames,
    provideNextUi = true,
    targetTailwindBaseStyles = true,
}) => (
    <AngularProvider injector={angularInjector}>
        {/* h-full is because we ran into the problem described at https://github.com/nextui-org/nextui/issues/1494. See https://trello.com/c/DG8Rob7G */}
        {provideNextUi ? (
            <NextUIProvider className={clsx({ 'h-full': classNames?.match('h-full') })}>
                <TailwindBaseContainer className={classNames} targetTailwindBaseStyles={targetTailwindBaseStyles}>
                    <Provider store={store}>{children}</Provider>
                </TailwindBaseContainer>
            </NextUIProvider>
        ) : (
            <TailwindBaseContainer className={classNames} targetTailwindBaseStyles={targetTailwindBaseStyles}>
                <Provider store={store}>{children}</Provider>
            </TailwindBaseContainer>
        )}
    </AngularProvider>
);

export const ReactWrapper = memo(ReactWrapperComponent) as typeof ReactWrapperComponent;
