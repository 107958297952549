import { useConfig } from 'FrontRoyalConfig';
import { StatsigProvider, useClientAsyncInit } from '@statsig/react-bindings';
import { StatsigSessionReplayPlugin } from '@statsig/session-replay';
import { StatsigAutoCapturePlugin } from '@statsig/web-analytics';
import { memo, type ReactNode } from 'react';
import Auid from 'Auid';
import { statsigClientProvider } from 'StatsigClientProvider';

function FrontRoyalStatsigProviderComponent({ children }: { children: ReactNode }) {
    const config = useConfig({ suspense: true });
    const statsigClientKey = config.statsigClientKey();
    const statsigUser = { userID: Auid.ensure() };
    const options = {
        plugins:
            config.appEnvType() === 'development'
                ? []
                : [new StatsigSessionReplayPlugin(), new StatsigAutoCapturePlugin()],
        environment: { tier: config.appEnvType() },
        networkConfig: { api: 'https://app.quantic.edu/statsig/v1' },
    };

    // Since we're using useClientAsyncInit and ignoring the isLoading property, the app will
    // render before statsig has loaded data via an http call. Use the useSuspenseFeatureGate hook
    // to prevent parts of the app that need statsig data from rendering until it's available.
    const { client } = useClientAsyncInit(statsigClientKey, statsigUser, options);
    statsigClientProvider.client = client;

    return <StatsigProvider client={client}>{children}</StatsigProvider>;
}

export const FrontRoyalStatsigProvider = memo(
    FrontRoyalStatsigProviderComponent,
) as typeof FrontRoyalStatsigProviderComponent;
