/* eslint-disable max-lines-per-function */
import { type BaseQueryFn, type FetchBaseQueryError, type TagDescription } from '@reduxjs/toolkit/query/react';
import { createApi } from 'ReduxHelpers';
import { type AnyObject, type EmptyObject } from '@Types';
import { allForUser, progressIsFetchedForUser } from 'StoredProgress';
import { minKey, maxKey } from 'DexieHelper';
import { logTinyEvent } from 'TinyEventLogger';
import { camelCaseStream, removeLessonContent, snakeCaseStream, type Stream } from 'Lessons';
import { camelCasePlaylist, snakeCasePlaylist, type Playlist } from 'Playlists';
import { mergeIncomingChanges } from 'LessonProgress';
import type FrontRoyalStoreDB from './FrontRoyalStoreDB';
import frontRoyalStoreProvider from './frontRoyalStoreProvider';
import {
    type AllProgressForUserResponse,
    type BookmarkedStream,
    type LessonProgressRecord,
    type StreamProgressRecord,
    type ProjectProgressRecord,
    type Image,
} from './FrontRoyalStore.types';
import { getPublishedStream } from './getPublishedStream';

const debug = false;

function logQuery(queryName: string) {
    if (!debug) return;

    // eslint-disable-next-line no-console
    console.log(`FrontRoyalStoreApi: ${queryName}`);
}

enum TagType {
    FrontRoyalStore = 'FrontRoyalStore',
    publishedStreamIdsWithAllContentStored = 'publishedStreamIdsWithAllContentStored',
    allProgressForUser = 'allProgressForUser',
    bookmarkedStreamsForUser = 'bookmarkedStreamsForUser',
    progressIsFetchedForUserTag = 'progressIsFetchedForUser',
    publishedStream = 'publishedStream',
    publishedStreams = 'publishedStreams',
    publishedPlaylists = 'publishedPlaylists',
    storedImage = 'storedImage',
}

const tagsThatAreInvalidatedByAnyStreamMutation = [
    TagType.publishedStreamIdsWithAllContentStored,
    TagType.publishedStreams,
];

type SaveProgressArgs =
    | {
          table: 'lessonProgress';
          records: LessonProgressRecord[];
      }
    | {
          table: 'streamProgress';
          records: StreamProgressRecord[];
      }
    | {
          table: 'projectProgress';
          records: ProjectProgressRecord[];
      };

type SaveProgressAttrsFromServerArgs =
    | {
          table: 'lessonProgress';
          record: LessonProgressRecord;
          incomingAttrs: LessonProgressRecord;
      }
    | {
          table: 'streamProgress';
          record: StreamProgressRecord;
          incomingAttrs: StreamProgressRecord;
      };

type DBQueryReturnValue<Result, Error> = ReturnType<BaseQueryFn<unknown, Result, Error, unknown>>;

async function queryDb<ResultType = unknown>(
    fn: (db: FrontRoyalStoreDB) => Promise<ResultType>,
): Promise<DBQueryReturnValue<ResultType, FetchBaseQueryError>> {
    const frontRoyalStore = frontRoyalStoreProvider.get()!;

    try {
        const db = await frontRoyalStore.getDb();
        const result = await fn(db);
        return { data: result };
    } catch (e) {
        const err = e as Error;
        return {
            error: {
                status: 500,
                data: {
                    name: err.name,
                    message: err.message,
                    stack: err.stack,
                },
            },
        };
    }
}

function getCacheTime({ minutes, forever }: { minutes?: number; forever?: boolean }) {
    if (process.env.NODE_ENV === 'test') return 0;

    if (forever) return Infinity;

    if (minutes) return minutes * 60 * 1000;

    throw new Error('Must specify either minutes or forever');
}

type QueryFn = (db: FrontRoyalStoreDB) => Promise<unknown>;

function providesTagsForUserId(tagTypes: TagType[]) {
    return (_result: AnyObject | undefined, error: FetchBaseQueryError | undefined, userId: string) => {
        const tags: TagDescription<TagType>[] = [TagType.FrontRoyalStore];

        if (error) return tags;

        return tagTypes.reduce((prev, type) => [...prev, { type, id: userId }], tags);
    };
}

function invalidateTagsForUserIds(userIds: string[], tagTypes: TagType[], error: FetchBaseQueryError | undefined) {
    if (error) return [];

    return userIds.flatMap<TagDescription<TagType>>(id => tagTypes.map(type => ({ id, type })));
}

function invalidatesTagsForUserIdParam(tagTypes: TagType[]) {
    return (_result: AnyObject | undefined, error: FetchBaseQueryError | undefined, userId: string) =>
        invalidateTagsForUserIds([userId], tagTypes, error);
}

function invalidatesTagsForStream(streamId: string) {
    const tags: TagDescription<TagType>[] = tagsThatAreInvalidatedByAnyStreamMutation;

    tags.push({ type: TagType.publishedStream, id: streamId });

    return tags;
}

function clearAllTables(db: FrontRoyalStoreDB) {
    const promises = db.tables.map(table => table.clear());
    return Promise.all(promises);
}

async function getStorableImage(url: string, db: FrontRoyalStoreDB) {
    const image = await db.images.where('url').equals(url).first();
    if (image) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            // Around 2024/04/05, we started storing image data as ArrayBuffers instead of Blobs.
            // At the time, we didn't want to blow away images that folks had already stored,
            // so we need to account for either type when reading the data out of the store.
            // See also: https://trello.com/c/jcJNLacb/1057-bug-storing-images-as-blobs-in-sqlite-on-ios-isnt-working
            const blob = image.data instanceof Blob ? image.data : new Blob([image.data]);
            reader.readAsDataURL(blob);
            reader.onload = e => {
                resolve(e.target?.result);
            };
            reader.onerror = reject;
            reader.onabort = reject;
        }).catch(e => {
            const error = e as Error;
            // See https://trello.com/c/cPb94OiC/519-05-bug-alerts-in-image-loading-errors
            // We are adding logging here to clarify if the images are erroring because they are in the db or not,
            // Based on events, I don't think we should see this get logged to sentry when we see 'image:load_failure' errors,
            // if we do, we might want to reconsider what we think is going on.
            logTinyEvent('getStorableImage:error', {
                error: error.message,
                message: `An error occurred trying to read the an image from the db and load it in rtk cache. The image url was "${url}"`,
            });
            return url;
        });
    }
    return url;
}

function prepareStreamToStore(stream: Stream) {
    const modifiedStream = removeLessonContent(stream);
    return snakeCaseStream(modifiedStream);
}

export const frontRoyalStoreApi = createApi('FrontRoyalStoreApi', {
    tagTypes: Object.values(TagType),

    // Nothing can change inside of IndexedDB that we don't have control over, so in most cases
    // we don't need to have a time-based expiry
    keepUnusedDataFor: getCacheTime({ forever: true }),
    baseQuery: async (fn: QueryFn) => queryDb(fn),
    endpoints: builder => ({
        clearAllTables: builder.mutation<EmptyObject, void>({
            query: () => async db => {
                logQuery('mutation.clearAllTables');
                await clearAllTables(db);
                return {};
            },
            invalidatesTags: [TagType.FrontRoyalStore],
        }),
        clearUserSpecificTables: builder.mutation<EmptyObject, void>({
            query: () => async db => {
                logQuery('mutation.clearUserSpecificTables');
                const promises = db.tables.map(table => {
                    // FrontRoyalStore#reinitializeDb assumes that the only non-user-specific table is
                    // configRecords. If this changes, review that method.
                    if (['configRecords'].includes(table.name)) return Promise.resolve();

                    return table.clear();
                });
                await Promise.all(promises);
                return {};
            },
            invalidatesTags: [TagType.FrontRoyalStore],
        }),
        publishedStreamIdsWithAllContentStored: builder.query<AnyObject<boolean>, void>({
            query: () => async db => {
                logQuery('query.publishedStreamIdsWithAllContentStored');
                const ids = await db.publishedStreams.where({ all_content_stored: 1 }).primaryKeys();
                return ids.reduce<AnyObject<boolean>>((obj, id) => {
                    obj[id] = true;
                    return obj;
                }, {});
            },
            providesTags: [TagType.FrontRoyalStore, TagType.publishedStreamIdsWithAllContentStored],
        }),

        setAllContentStoredOnStream: builder.mutation<EmptyObject, string>({
            query: streamId => async db => {
                logQuery('mutation.setAllContentStoredOnStream');
                await db.publishedStreams.update(streamId, { all_content_stored: 1 });
                return {};
            },

            invalidatesTags: (_result, _error, streamId) => invalidatesTagsForStream(streamId),
        }),

        storeStream: builder.mutation<EmptyObject, Stream<boolean>>({
            query: stream => async db => {
                logQuery('mutation.storeStream');
                await db.publishedStreams.put(prepareStreamToStore(stream));
                return {};
            },
            invalidatesTags: (_result, _error, stream) => invalidatesTagsForStream(stream.id),
        }),

        allProgressForUser: builder.query<AllProgressForUserResponse, string>({
            query: userId => async db => {
                logQuery('query.allProgressForUser');
                if (!(await progressIsFetchedForUser(userId, db))) return { fetched: false };

                let streamProgressRecords: StreamProgressRecord[] = [];
                let lessonProgressRecords: LessonProgressRecord[] = [];
                let projectProgressRecords: ProjectProgressRecord[] = [];

                // Run these three queries in parallel
                await Promise.all([
                    allForUser(db.streamProgress, userId)
                        .toArray()
                        .then(r => {
                            streamProgressRecords = r;
                        }),

                    allForUser(db.lessonProgress, userId)
                        .toArray()
                        .then(r => {
                            lessonProgressRecords = r;
                        }),

                    allForUser(db.projectProgress, userId)
                        .toArray()
                        .then(r => {
                            projectProgressRecords = r;
                        }),
                ]);

                return {
                    fetched: true,
                    streamProgressRecords,
                    lessonProgressRecords,
                    projectProgressRecords,
                };
            },
            providesTags: providesTagsForUserId([TagType.allProgressForUser, TagType.progressIsFetchedForUserTag]),
        }),

        bookmarkedStreamsForUser: builder.query<BookmarkedStream[], string>({
            // These are initially set in the initializer for the frontRoyalStore angular
            // module and then kept updated in the currentUserInterceptor from push messages.
            // There is a checkbox on https://trello.com/c/At6NM5M5 which talks about
            // moving this information to a flag on streams_progress so that we could detect
            // changes to individual bookmarks, rather than having to push down the full list.
            query: userId => db => {
                logQuery('query.bookmarkedStreamsForUser');
                return allForUser(db.bookmarkedStreams, userId).toArray();
            },
            providesTags: providesTagsForUserId([TagType.bookmarkedStreamsForUser]),
        }),

        setStreamBookmarks: builder.mutation<EmptyObject, { userId: string; newLocalePackIds: string[] }>({
            query:
                ({ userId, newLocalePackIds }) =>
                async db => {
                    logQuery('mutation.setStreamBookmarks');
                    await db.safeTransaction('rw', [db.bookmarkedStreams], () =>
                        db.bookmarkedStreams
                            .where('[user_id+locale_pack_id]')
                            .between([userId, minKey], [userId, maxKey])
                            .toArray()
                            .then(currentRecords => {
                                const currentLocalePackIds = currentRecords.map(({ locale_pack_id }) => locale_pack_id);

                                const localePackIdsToRemove = currentLocalePackIds.filter(
                                    id => !newLocalePackIds.includes(id),
                                );
                                const localePackIdsToAdd = newLocalePackIds.filter(
                                    id => !currentLocalePackIds.includes(id),
                                );

                                const promises = [];
                                if (localePackIdsToRemove.length > 0) {
                                    const keys = localePackIdsToRemove.map(id => [userId, id]);
                                    promises.push(
                                        db.bookmarkedStreams.where('[user_id+locale_pack_id]').anyOf(keys).delete(),
                                    );
                                }

                                if (localePackIdsToAdd.length > 0) {
                                    const records = localePackIdsToAdd.map(id => ({
                                        user_id: userId,
                                        locale_pack_id: id,
                                    }));
                                    promises.push(db.bookmarkedStreams.bulkPut(records));
                                }

                                return Promise.all(promises);
                            }),
                    );
                    return {};
                },
            invalidatesTags: (_result, error, { userId }) =>
                invalidateTagsForUserIds([userId], [TagType.bookmarkedStreamsForUser], error),
        }),

        clearProgressForUser: builder.mutation<EmptyObject, string>({
            query: userId => db => {
                logQuery('mutation.clearProgressForUser');
                const promises = [
                    allForUser(db.streamProgress, userId).delete(),
                    allForUser(db.lessonProgress, userId).delete(),
                    allForUser(db.projectProgress, userId).delete(),
                    allForUser(db.bookmarkedStreams, userId).delete(),
                    db.progressFetches.where({ user_id: userId }).delete(),
                ];

                return Promise.all(promises).then(() => ({}));
            },
            invalidatesTags: (_result, _error, userId) => {
                const tags = [
                    TagType.bookmarkedStreamsForUser,
                    TagType.allProgressForUser,
                    TagType.progressIsFetchedForUserTag,
                ] as const;
                return tags.map(tagType => ({
                    type: tagType,
                    id: userId,
                }));
            },
        }),

        saveProgress: builder.mutation<EmptyObject, SaveProgressArgs>({
            query:
                ({ table, records }) =>
                async db => {
                    logQuery('mutation.saveProgress');
                    if (records.length > 0) {
                        // We need these if checks here to work as type guards to so ts
                        // can correctly match the records type to the correct table
                        // based on the SaveProgressArgs type
                        if (table === 'lessonProgress') {
                            await db[table].bulkPut(records);
                        } else if (table === 'streamProgress') {
                            await db[table].bulkPut(records);
                        } else {
                            await db[table].bulkPut(records);
                        }
                    }

                    return {};
                },
            invalidatesTags: (_result, error, { records }) => {
                if (error) return [];

                const userIds = [...new Set(records.map(r => r.user_id))];
                return userIds.map(userId => ({
                    type: TagType.allProgressForUser,
                    id: userId,
                }));
            },
        }),

        setProgressFetchedForUser: builder.mutation<EmptyObject, string>({
            query: userId => async db => {
                logQuery('mutation.setProgressFetchedForUser');
                await db.progressFetches.put({ user_id: userId });
                return {};
            },
            invalidatesTags: invalidatesTagsForUserIdParam([TagType.progressIsFetchedForUserTag]),
        }),

        saveProgressAttrsFromServer: builder.mutation<EmptyObject, SaveProgressAttrsFromServerArgs>({
            query:
                ({ table, record, incomingAttrs }) =>
                async db => {
                    logQuery('mutation.saveProgressAttrsFromServer');
                    // This type guard is a bit annoying but is the only way to
                    // make ts happy that the table and record type match
                    if (table === 'lessonProgress') {
                        // Merge in any modifications that came from the server.
                        // If the version in indexed db is still the version that We
                        // saved, mark it as synced.
                        await db[table]
                            .where({
                                '[user_id+locale_pack_id]': [record.user_id, record.locale_pack_id],
                            })
                            .modify((existingRecord, ref) => {
                                const updatedRecord = {
                                    ...existingRecord,
                                    ...mergeIncomingChanges(existingRecord, incomingAttrs),
                                };
                                ref.value = {
                                    ...updatedRecord,
                                    synced_to_server: existingRecord.fr_version === record.fr_version ? 1 : 0,
                                };
                            });
                    } else {
                        await db[table]
                            .where({
                                '[user_id+locale_pack_id]': [record.user_id, record.locale_pack_id],
                            })
                            .modify((existingRecord, ref) => {
                                const updatedRecord = {
                                    ...existingRecord,
                                    ...incomingAttrs,

                                    // We want to replicate what the server does by making sure to never set
                                    // `complete` or `completed_at` from a truthy value back to a falsey value.
                                    complete: existingRecord.complete || incomingAttrs.complete,
                                    completed_at: existingRecord.completed_at || incomingAttrs.completed_at,
                                };
                                ref.value = {
                                    ...updatedRecord,
                                    synced_to_server: existingRecord.fr_version === record.fr_version ? 1 : 0,
                                };
                            });
                    }
                    return {};
                },
            invalidatesTags: (_result, error, { record }) =>
                invalidateTagsForUserIds([record.user_id], [TagType.allProgressForUser], error),
        }),

        storeImages: builder.mutation<EmptyObject, Image[]>({
            query: images => async db => {
                logQuery('mutation.storeImages');
                await db.images.bulkPut(images);
                return {};
            },
            invalidatesTags: (_result, _error, images) =>
                images.map(image => ({
                    type: TagType.storedImage,
                    id: image.url,
                })),
        }),

        getStorableImage: builder.query<{ data: Blob }, string>({
            query: url => async db => getStorableImage(url, db),
            providesTags: (_result, _error, url) => [TagType.FrontRoyalStore, { type: TagType.storedImage, id: url }],

            // We don't want to cache lesson images forever, because we generally only need them once and we could take
            // up loads of space in RAM. We want to cache them for a little while, though, so that our image preloading
            // in the player will work to make things snappy
            keepUnusedDataFor: getCacheTime({ minutes: 5 }),
        }),

        getStorableImageAndCacheForever: builder.query<{ data: Blob }, string>({
            query: url => async db => getStorableImage(url, db),
            providesTags: (_result, _error, url) => [TagType.FrontRoyalStore, { type: TagType.storedImage, id: url }],
        }),

        deleteContentStoredForOfflineMode: builder.mutation<EmptyObject, void>({
            query: () => async db => {
                await db.safeTransaction('rw', [db.images, db.publishedLessonContent, db.publishedStreams], () =>
                    Promise.all([
                        db.images.clear(),
                        db.publishedLessonContent.clear(),
                        db.publishedStreams.where({ all_content_stored: 1 }).modify({ all_content_stored: 0 }),
                    ]),
                );

                return {};
            },
            invalidatesTags: [TagType.publishedStreamIdsWithAllContentStored],
        }),

        // getPublishedStreams returns the full set of all records in publishedStreams, converted to camelCase
        getPublishedStreams: builder.query<Stream[], void>({
            query: () => async db => {
                const result = (await db.publishedStreams.toArray()).map(s => camelCaseStream(s)!);
                return result;
            },
            providesTags: [TagType.FrontRoyalStore, TagType.publishedStreams],
        }),

        // getPublishedStream returns a single stream, and provides the option of copying frame
        // data from publishedLessonContent into the stream record before returning it.
        getPublishedStream: builder.query<Stream | null, Parameters<typeof getPublishedStream>[0]>({
            query: getPublishedStream,
            providesTags: (_result, _error, { id }: { id: string }) => {
                const tags: TagDescription<TagType>[] = [TagType.FrontRoyalStore];

                tags.push({ type: TagType.publishedStream, id });

                return tags;
            },
        }),

        getPublishedPlaylists: builder.query<Playlist[], void>({
            query: () => async db => (await db.publishedPlaylists.toArray()).map(p => camelCasePlaylist(p)!),
            providesTags: [TagType.FrontRoyalStore, TagType.publishedPlaylists],
        }),

        bulkPutPublishedStreams: builder.mutation<EmptyObject, Stream[]>({
            query: streams => async db => {
                await db.publishedStreams.bulkPut(streams.map(prepareStreamToStore));
                return {};
            },
            invalidatesTags: tagsThatAreInvalidatedByAnyStreamMutation,
        }),

        bulkPutPublishedPlaylists: builder.mutation<EmptyObject, Playlist[]>({
            query: playlists => async db => {
                await db.publishedPlaylists.bulkPut(playlists.map(snakeCasePlaylist).filter(v => !!v));
                return {};
            },
            invalidatesTags: [TagType.publishedPlaylists],
        }),
    }),
});

export default frontRoyalStoreApi;
