import { useFeatureGate } from '@statsig/react-bindings';
import delay from 'delay';

function reasonIndicatesLoaded(reason: string) {
    // I have not found documentation for the various reasons, but empirically
    // it seems like only 'Loading' means we should wait. Other reasons are
    //  'Cache:' which seems to indicate that the value was pulled from local storage after
    //              having previously been pulled from a network request
    //  'Network:' which seems to indicate that the value was pulled from a network request
    //  'NetworkNotModified:' which seems to indicate that after initially relying on a cached value,
    //              a network request was made and showed that the cached value was still valid
    if (reason.startsWith('Loading')) return false;

    return true;
}

export function useSuspenseFeatureGate(gateName: string) {
    const gate = useFeatureGate(gateName);

    const reason = gate.details.reason;
    if (!reasonIndicatesLoaded(reason)) {
        throw delay(100);
    }

    return gate.value;
}
