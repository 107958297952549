window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.FrontRoyalStore = window.locales.am.FrontRoyalStore || {};window.locales.am.FrontRoyalStore.showStorageQuotaExceededModal = window.locales.am.FrontRoyalStore.showStorageQuotaExceededModal || {};window.locales.am.FrontRoyalStore.showStorageQuotaExceededModal = {...window.locales.am.FrontRoyalStore.showStorageQuotaExceededModal, ...{
    "title": "የማከማቻ ገደብ አልፏል",           
    "continue": "ቀጥል",           
    "in_offline_mode_message": "በመሳሪያዎ ላይ ለ{{brandName}} መተግበሪያ ከተፈቀደው የማከማቻ አቅም በላይ አልፈዋል። ለመቀጠል፣ በመሳሪያዎ ላይ ተጨማሪ ክፍት ቦታ ማስለቀቅ ወይም ወደ ኦንላይን መመለስ አለብዎት።",           
    "not_in_offline_mode_message": "በመሳሪያዎ ላይ ለ{{brandName}} መተግበሪያ ከተፈቀደው የማከማቻ አቅም በላይ አልፈዋል። ለመቀጠል፣ በመሳሪያዎ ላይ ተጨማሪ ክፍት ቦታ ማስለቀቅ አለብዎት።"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.FrontRoyalStore = window.locales.ar.FrontRoyalStore || {};window.locales.ar.FrontRoyalStore.showStorageQuotaExceededModal = window.locales.ar.FrontRoyalStore.showStorageQuotaExceededModal || {};window.locales.ar.FrontRoyalStore.showStorageQuotaExceededModal = {...window.locales.ar.FrontRoyalStore.showStorageQuotaExceededModal, ...{
    "title": "تم تجاوز حد التخزين",
    "continue": "يكمل",
    "in_offline_mode_message": "لقد تجاوزت سعة التخزين المسموح بها لتطبيق {{brandName}} على جهازك. للمتابعة ، يجب عليك إخلاء مساحة أكبر على جهازك أو معاودة الاتصال بالإنترنت.",
    "not_in_offline_mode_message": "لقد تجاوزت سعة التخزين المسموح بها لتطبيق {{brandName}} على جهازك. للمتابعة ، يجب عليك إخلاء مساحة أكبر على جهازك."
}
};window.locales.en = window.locales.en || {};window.locales.en.FrontRoyalStore = window.locales.en.FrontRoyalStore || {};window.locales.en.FrontRoyalStore.showStorageQuotaExceededModal = window.locales.en.FrontRoyalStore.showStorageQuotaExceededModal || {};window.locales.en.FrontRoyalStore.showStorageQuotaExceededModal = {...window.locales.en.FrontRoyalStore.showStorageQuotaExceededModal, ...{
    "title": "Storage Limit Exceeded",
    "continue": "Continue",
    "in_offline_mode_message": "You've exceeded the storage capacity allowed for the {{brandName}} app on your device. In order to continue, you must free up more space on your device or go back online.",
    "not_in_offline_mode_message": "You've exceeded the storage capacity allowed for the {{brandName}} app on your device. In order to continue, you must free up more space on your device."
}
};window.locales.es = window.locales.es || {};window.locales.es.FrontRoyalStore = window.locales.es.FrontRoyalStore || {};window.locales.es.FrontRoyalStore.showStorageQuotaExceededModal = window.locales.es.FrontRoyalStore.showStorageQuotaExceededModal || {};window.locales.es.FrontRoyalStore.showStorageQuotaExceededModal = {...window.locales.es.FrontRoyalStore.showStorageQuotaExceededModal, ...{
    "title": "Límite de almacenamiento excedido",
    "continue": "Continuar",
    "in_offline_mode_message": "Excediste la capacidad de almacenamiento permitida para la aplicación {{brandName}} en tu dispositivo. Para continuar, debe liberar más espacio en su dispositivo o volver a conectarse.",
    "not_in_offline_mode_message": "Excediste la capacidad de almacenamiento permitida para la aplicación {{brandName}} en tu dispositivo. Para continuar, debe liberar más espacio en su dispositivo."
}
};window.locales.it = window.locales.it || {};window.locales.it.FrontRoyalStore = window.locales.it.FrontRoyalStore || {};window.locales.it.FrontRoyalStore.showStorageQuotaExceededModal = window.locales.it.FrontRoyalStore.showStorageQuotaExceededModal || {};window.locales.it.FrontRoyalStore.showStorageQuotaExceededModal = {...window.locales.it.FrontRoyalStore.showStorageQuotaExceededModal, ...{
    "title": "Limite di archiviazione superato",
    "continue": "Continua",
    "in_offline_mode_message": "Hai superato la capacità di archiviazione consentita per l' {{brandName}} sul tuo dispositivo. Per continuare, devi liberare più spazio sul tuo dispositivo o tornare online.",
    "not_in_offline_mode_message": "Hai superato la capacità di archiviazione consentita per l' {{brandName}} sul tuo dispositivo. Per continuare, devi liberare più spazio sul tuo dispositivo."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.FrontRoyalStore = window.locales.zh.FrontRoyalStore || {};window.locales.zh.FrontRoyalStore.showStorageQuotaExceededModal = window.locales.zh.FrontRoyalStore.showStorageQuotaExceededModal || {};window.locales.zh.FrontRoyalStore.showStorageQuotaExceededModal = {...window.locales.zh.FrontRoyalStore.showStorageQuotaExceededModal, ...{
    "title": "存储超过限制",           
    "continue": "继续",           
    "in_offline_mode_message": "你的设备存储容量有限，无法安装{{brandName}}应用程序。请释放更多设备空间或恢复在线状态以继续。",           
    "not_in_offline_mode_message": "你的设备存储容量有限，无法安装{{brandName}}应用程序。请释放更多设备空间以继续。"           
}
};